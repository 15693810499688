
  @font-face {
    font-family: 'Futura';
    src: url('./fonts/futura/Futura\ Book\ font.ttf') format('truetype')
  }

  p {
    font-family: 'Futura';
    font-weight: bold;
  }

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modal-content {
  width: 717px !important;
  height: 450px !important;
}

#right-section {
  width: 50%;
  position: absolute;
  right: 0;
  height: 100%;
}

#left-section {
  width: 50%;
  position: absolute;
  left: 0;
  height: 100%;
  background-color: #FFF;
  text-align: center;
}

.hh-dropdown {
    width: 100%;
    margin: 10px 0px;
    padding: 10px;
    font-size: 14px;
}

#crown-heading-img {
  width: 230px;
  height: 70px;
  margin: 20px;
}

#crown-building-img {
  width: 100%;
}

#crown-front-text {
  width: 100%;
}

#right-column {
  border: 1px solid green;
  height: 370px;
  width: 75%;
}

.select-field {
  width: 75%;
  height: 30px;
  margin: 10px;
}

#select-field-container {
  text-align: left;
  margin: 30px;
}


#input-field-container {
  margin: 30px 0px;
  display: grid;
  justify-items: center;
}

.input-field {
  width: 75%;
  height: 30px;
  margin: 10px;
  font-family: 'Futura';
}

#continue-button {
  position: absolute;
  bottom: 10px;
  right: 20px;
  background-color: #810002;
  font-weight: 700;
  outline: none !important;
}

#back-button {
  position: absolute;
  bottom: 10px;
  right: 170px;
  background-color: #DFDFDF;
  color: #000;
  font-weight: 700;

}

#button-container {
  width: inherit;
  text-align: center;
  height: 100px;
  border: 1px solid black;
  position: absolute;
  bottom: 0;
}

#contact-button {
  bottom: 10px;
  background-color: #810002;
  font-weight: 700;
  outline: none !important;
}

#confrirmation-wrapper {
  display: inline-block;
}

#confirmation-header {
  height: 70px;
  background-color: #FFF;
  border: 1px solid gray;
  text-align: center;
}

#confirmation-section {
  background-color: #D9D9D9;
  height: 100%;
  width: 100%;
  text-align: center;
  display: inline-block;
}

#confirmation-response {

  margin: auto;
  background: #fff;
  border-radius: 7px;
  height: 200px;
  width: 422px;

}


#next-steps-text {
  color: #810002;
  margin-top: 25px;
}

#small-text {
  font-size: 10px;
  color: #810002;
  width: 75%;
}

.select-field-text {
  font-size: 15px;
  margin-bottom: 0px;
}

#response-content {
  padding-top: 10px;
  margin: 10px;
}

#app-form {
  margin-left: '50px';
  margin-top: '50px'
}


















/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
}

/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
}

/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 

}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) { 

}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3) { 

}

/* Portrait */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 

}

/* Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 

}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 

    .modal-content {
      /* width: 90% !important;
      height: 80vh !important;
      margin: auto !important;
      position: initial !important; */

      display: grid;
      flex-direction: column-reverse !important;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0,0,0,.2);
      border-radius: .3rem;
      outline: 0;
      padding-top: 100px !important;
    }

    #left-section {
      width: 50%;
      height: 100% !important;
      background-color: #FFF;
      text-align: center;
      position: inherit;
    }

    #right-section {
      height: 50% !important;
      position: inherit;
      background-color: white !important;
      right: 0 !important;
      width: 100% !important;
      margin-bottom: 200px !important;
      /* display: none !important; */
    }

    #crown-heading-img {
      width: 230px;
      height: 70px;
      margin: 20px;
      position: absolute !important;
      top: 50px !important;
      right: 15%;
    }

    #button-container {
      position: absolute !important;
      bottom: 10px !important;
    }

    #select-field-container {
      text-align: center;
      text-emphasis: center;
      margin: auto;
    }

    .select-field {
      margin: 15px;
    }

    .select-field-text {
      text-align: left;
    }

    #crown-building-img {
      display: none;
    }

    #crown-front-text {
      display: none;
    }

    #app-form {
      margin-left: '0px';
      margin-top: '0px'
    }

    #confirmation-response {

      margin: auto;
      background: #fff;
      border-radius: 7px;
      height: 200px;
      /* width: 422px; */
    
    }

    #confirmation-section {
      display: none;
    }

    #continue-button {
      position: absolute;
      /* bottom: 10px; */
      right: 120px;
      background-color: #810002;
      font-weight: 700;
      outline: none !important;
      margin-top: 30px;
  }

  #next-steps-text {
    color: #810002;
    margin: 25px;
    padding-top: 25px;
  }
  

}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 

}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 

}

@media only screen and (min-width: 360px) and (max-width: 767px) { 

  .modal-content {
    display: grid;
    width: 90% !important;
    height: 80vh !important;
    margin: auto !important;
    position: initial !important;
    flex-direction: column-reverse !important;
    padding-top: 200px !important;
  }

  #left-section {
    width: 50%;
    height: 100% !important;
    background-color: #FFF;
    text-align: center;
    position: inherit;
  }

  #right-section {
    height: 50% !important;
    position: inherit;
    background-color: white !important;
    right: 0 !important;
    width: 100% !important;
    margin-bottom: 200px !important;
    /* display: none !important; */
  }

  #crown-heading-img {
    width: 230px;
    height: 70px;
    margin: 20px;
    position: absolute !important;
    top: 50px !important;
    right: 15%;
  }

  #button-container {
    position: absolute !important;
    bottom: 10px !important;
  }

  #select-field-container {
    margin: 20px;
    text-align: center;
  }

  #select-field {
    margin: 15px;
    width: 80%;
    margin-left: 10px;
  }

  .select-field-text {
    text-align: left;
  }

  #crown-building-img {
    display: none;
  }

  #crown-front-text {
    display: none;
  }

  #app-form {
    margin-left: '0px';
    margin-top: '0px'
  }

  
  #confirmation-response {

    margin: auto;
    background: #fff;
    border-radius: 7px;
    height: 200px;
    width: fit-content !important;
  
  }

  #confirmation-section {
    background-color: #fff;
    display: block;
  }

  #continue-button {
    position: absolute;
    /* bottom: 10px; */
    right: 120px;
    background-color: #810002;
    font-weight: 700;
    outline: none !important;
    margin-top: 30px;
  }

  #next-steps-text {
    color: #810002;
    margin: 25px;
    padding-top: 25px;
  }


}